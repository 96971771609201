import React from 'react';
import { SVGLogoProps } from '../utils';

export default function MonochromeLogoMark({
  fill,
  className,
  ...rest
}: SVGLogoProps) {
  return (
    <svg {...rest} viewBox="0 0 216.56 448.5" className={className}>
      <title>MongoDB LogoMark</title>
      <path
        fill={fill}
        d="M202.77,179.74c-23-101.51-77.36-134.89-83.21-147.64-6.42-9-12.89-25-12.89-25-.11-.27-.28-.75-.48-1.21-.66,9-1,12.47-9.51,21.54-13.17,10.3-80.75,67-86.25,182.33C5.3,317.32,88.13,381.42,99.36,389.59l1.27.9,0-.08c.07.54,3.55,25.6,6,52.16l8.77,0a516.82,516.82,0,0,1,9.26-55.85l.68-.46a145.43,145.43,0,0,0,14.3-11.7l.51-.46c23.81-22,66.62-72.88,66.2-154A246.84,246.84,0,0,0,202.77,179.74ZM105.68,328.86s0-150.86,5-150.83c3.88,0,8.91,194.59,8.91,194.59C112.66,371.79,105.68,340.6,105.68,328.86Z"
      />
    </svg>
  );
}
