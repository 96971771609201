import React from 'react';
import { SVGLogoProps } from '../utils';

export default function MonochromeLogo({
  fill,
  className,
  ...rest
}: SVGLogoProps) {
  return (
    <svg {...rest} viewBox="0 0 1112.61 300" className={className}>
      <title>MongoDB Logo</title>
      <path
        fill={fill}
        d="M1088.41,233.64A16.61,16.61,0,1,1,1105,216.8a16.4,16.4,0,0,1-16.61,16.84m0-31.72a15.12,15.12,0,1,0,15,15.16,14.87,14.87,0,0,0-15-15.16m4.06,25.61-4.29-9.23h-3.46v9.23h-2.51v-21h6.2c4.62,0,6.53,2.05,6.53,5.88,0,3.08-1.45,5-4.15,5.59l4.43,9.52Zm-7.75-11.43h3.64c2.94,0,4.06-1,4.06-3.68s-1.07-3.6-4.38-3.6h-3.32Z"
      />
      <path
        fill={fill}
        d="M841.24,218.5c4.5,3.6,13.48,5.08,21.4,5.08,10.26,0,20.31-1.9,30.14-10.78,10.06-9.1,16.9-23.06,16.9-45.26,0-21.36-8.13-38.7-24.81-48.85-9.41-5.92-21.59-8.25-35.5-8.25-4.06,0-8.13.21-10.48,1.27a5.56,5.56,0,0,0-1.92,3c-.43,3.81-.43,32.78-.43,49.91,0,17.55,0,42.08.43,45,.21,2.54,1.5,7,4.27,8.88M798.56,100.93c3.63,0,17.45.63,23.87.63,12,0,20.31-.63,42.77-.63,18.82,0,34.65,5.08,46,14.8,13.69,11.84,21,28.34,21,48.43,0,28.54-13,45-26.09,54.34-13,9.73-29.95,15.23-54.11,15.23-12.83,0-34.86-.42-53.26-.63h-.25c-.85-1.7,1.58-8.29,3.08-8.46,5-.57,6.32-.76,8.63-1.71,3.88-1.6,4.8-3.58,5.23-10.56.64-13.11.43-28.76.43-46.52,0-12.69.21-37.43-.22-45.26-.64-6.55-3.41-8.24-9-9.51a118.52,118.52,0,0,0-12-1.91c-.43-1.27,2.86-7,3.93-8.24"
      />
      <path
        fill={fill}
        d="M985.2,111.66c-.85.21-1.93,2.33-1.93,3.39-.21,7.61-.42,27.49-.42,41.23a1.37,1.37,0,0,0,1.07,1.06c2.78.21,9.62.43,15.39.43,8.13,0,12.84-1.07,15.4-2.34,6.85-3.38,10-10.78,10-18.81,0-18.4-12.83-25.38-31.86-25.38a57.48,57.48,0,0,0-7.7.42m48.55,84.59c0-18.61-13.69-29.18-38.72-29.18-1.06,0-9-.22-10.9.21-.64.21-1.28.63-1.28,1.06,0,13.32-.22,34.68.42,43.14.43,3.59,3,8.67,6.21,10.15,3.42,1.9,11.12,2.32,16.47,2.32,14.75,0,27.8-8.24,27.8-27.7M946.57,101.3c1.93,0,7.62.63,22.16.63,13.69,0,24.81-.42,38.07-.42,16.47,0,39.14,5.93,39.14,30.45,0,12.06-8.56,21.78-19.68,26.44-.64.21-.64.63,0,.84,15.83,4,29.73,13.75,29.73,32.36,0,18.18-11.33,29.61-27.8,36.79-10.06,4.45-22.46,5.92-35.08,5.92-9.62,0-35.41-1.05-49.74-.84-1.5-.63,1.37-7.4,2.65-8.46a39.93,39.93,0,0,0,9.7-1.52c5.13-1.26,5.74-2.92,6.38-10.53.43-6.56.43-30,.43-46.74,0-22.84.21-38.27,0-45.89-.22-5.92-2.35-7.83-6.42-8.88-3.2-.64-8.55-1.27-12.82-1.9-1.08-1.06,2.22-7.41,3.28-8.25"
      />
      <path
        fill={fill}
        d="M179.51,233.64a9.24,9.24,0,0,1-.66-4.28,5.5,5.5,0,0,1,.66-2.85,73.34,73.34,0,0,0,8.21-1.67c3.78-.94,5.2-3,5.43-7.83.62-11.4.65-32.79.43-47.83v-.44c0-1.62,0-3.82-2-5.38a43,43,0,0,0-11.34-5c-1.8-.53-2.79-1.47-2.73-2.57S178.7,153.4,181,153c6.19-.64,22.43-4.51,28.8-7.46a4.2,4.2,0,0,1,.86,3c0,.66-.1,1.36-.17,2.08-.18,2.13-.38,4.54-.38,6.94a1.58,1.58,0,0,0,2.68.91c12.15-9.52,23-12.92,28.6-12.92,9.18,0,16.33,4.39,21.86,13.42a1.49,1.49,0,0,0,1.25.73,1.39,1.39,0,0,0,1.15-.62c11.17-8.47,22.24-13.53,29.63-13.53,17.51,0,28,13.11,28,35.06,0,6.31-.06,14.3-.12,21.77-.05,6.54-.1,12.68-.1,16.93,0,1,1.38,3.88,3.45,4.45,2.56,1.25,6.26,1.89,10.93,2.69l.18,0c.36,1.26-.38,6.15-1.1,7.15-1.16,0-2.76-.09-4.76-.19-3.63-.18-8.62-.44-14.42-.44-11.63,0-17.71.23-23.52.61-.43-1.45-.59-6.14-.05-7.11a64.2,64.2,0,0,0,7.09-1.67c3.7-1.22,4.77-2.9,5-7.82.09-3.5.76-34.28-.43-41.59-1.1-7.57-6.8-16.44-19.29-16.44-4.63,0-12.11,1.93-19.25,7.34a2.84,2.84,0,0,0-.69,1.81v.15c.84,3.94.84,8.54.84,15.51,0,4,0,8.17-.05,12.35-.05,8.49-.09,16.5.05,22.55,0,4.13,2.49,5.12,4.5,5.91,1.09.24,2,.45,2.82.65,1.68.41,3.42.83,6,1.24a11.49,11.49,0,0,1-.1,5.32,4,4,0,0,1-.75,1.82c-6.46-.22-13.1-.42-22.68-.42-2.9,0-7.64.13-11.83.24-3.38.09-6.6.18-8.42.19A8.34,8.34,0,0,1,236,230a6.38,6.38,0,0,1,.67-3.44l2.57-.47c2.24-.4,4.18-.74,6-1.2,3.17-1,4.36-2.73,4.6-6.76.62-9.41,1.1-36.52-.23-43.31-2.27-10.89-8.47-16.42-18.44-16.42-5.84,0-13.21,2.81-19.23,7.33a5.83,5.83,0,0,0-1.78,4.35c0,3.23,0,7.08,0,11.2,0,13.61-.09,30.56.24,37.9.2,2.25,1,4.93,5.24,5.92.93.27,2.53.53,4.39.84,1.08.18,2.25.37,3.45.59a15.21,15.21,0,0,1-.57,7.16c-1.86,0-4.15-.12-6.77-.24-4-.17-9-.4-14.67-.4-6.69,0-11.34.23-15.08.4-2.51.13-4.68.23-6.85.24"
      />
      <path
        fill={fill}
        d="M394.18,154.55A18.28,18.28,0,0,0,384,157.42c-7.42,4.52-11.21,13.53-11.21,26.78,0,24.81,12.43,42.14,30.21,42.14A19,19,0,0,0,416,221.73c5.46-4.46,8.36-13.57,8.36-26.32,0-24.44-12.14-40.86-30.21-40.86m3.43,81C365.47,235.54,354,212,354,189.92c0-15.41,6.3-27.46,18.72-35.81a57.11,57.11,0,0,1,28.92-8.56c24.52,0,41.65,17.64,41.65,42.89,0,17.16-6.85,30.71-19.79,39.18-6.21,3.81-17,7.92-25.92,7.92"
      />
      <path
        fill={fill}
        d="M721.32,154.55a18.27,18.27,0,0,0-10.24,2.87c-7.42,4.52-11.21,13.53-11.21,26.78,0,24.81,12.42,42.14,30.21,42.14a19,19,0,0,0,13.08-4.62c5.46-4.45,8.36-13.56,8.36-26.31,0-24.44-12.14-40.86-30.2-40.86m3.42,81c-32.14,0-43.58-23.57-43.58-45.62,0-15.42,6.3-27.46,18.73-35.81a57,57,0,0,1,28.91-8.56c24.52,0,41.65,17.64,41.65,42.88,0,17.18-6.85,30.72-19.79,39.18-6.21,3.82-17,7.93-25.92,7.93"
      />
      <path
        fill={fill}
        d="M613.14,153.7c-9.91,0-16.31,7.82-16.31,19.93s5.51,26.49,21,26.49c2.67,0,7.5-1.19,9.89-3.82,3.6-3.31,5.95-10.16,5.95-17.38,0-15.79-7.68-25.22-20.54-25.22m-1.29,83.53a16.41,16.41,0,0,0-8,2c-7.84,5-11.47,10.05-11.47,15.83,0,5.45,2.12,9.78,6.66,13.63,5.51,4.66,12.93,6.93,22.69,6.93,19.21,0,27.82-10.33,27.82-20.56,0-7.13-3.58-11.9-10.93-14.6-5.67-2.08-15.14-3.22-26.73-3.22m1.29,49.07c-11.54,0-19.85-2.44-26.95-7.9-6.9-5.33-10-13.24-10-18.7a14.61,14.61,0,0,1,3.78-9.51c2-2.29,6.67-6.56,17.42-14a.9.9,0,0,0,.57-.85.89.89,0,0,0-.68-.88c-8.87-3.39-11.54-9-12.34-12,0-.11-.05-.28-.09-.47-.26-1.19-.5-2.3,1.11-3.46,1.24-.88,3.23-2.06,5.35-3.31a71,71,0,0,0,8.59-5.6,1.35,1.35,0,0,0,.37-1.19,1.39,1.39,0,0,0-.72-1c-13.12-4.4-19.72-14.15-19.72-29a28.87,28.87,0,0,1,12.11-23.76c5.28-4.17,18.52-9.18,27.1-9.18h.5c8.82.21,13.8,2.06,20.68,4.61a32.89,32.89,0,0,0,12.23,2c7.3,0,10.49-2.32,13.23-5a11.39,11.39,0,0,1,.7,3.78A14.17,14.17,0,0,1,664,159.6c-1.5,2.07-5,3.58-8.22,3.58-.34,0-.65,0-1-.05a29.36,29.36,0,0,1-5-.83l-.82.28c-.26.39-.09.81.12,1.35a2.74,2.74,0,0,1,.12.32,58.87,58.87,0,0,1,1.19,8.11c0,15.57-6.13,22.35-12.77,27.37a43.59,43.59,0,0,1-22.07,8.53h0c-.17,0-1,.08-2.55.22-1,.09-2.31.2-2.5.2l-.19,0c-1.44.41-5.21,2.2-5.21,5.52,0,2.77,1.71,6.21,9.84,6.82l5.26.37c10.74.75,24.14,1.68,30.45,3.82a21.08,21.08,0,0,1,14.08,20.29c0,14-9.92,27.1-26.55,35.12a57.66,57.66,0,0,1-25.11,5.65"
      />
      <path
        fill={fill}
        d="M564,226.19c-4.71-.63-8.14-1.27-12.19-3.17a5.56,5.56,0,0,1-1.5-3c-.43-6.56-.43-25.59-.43-38.07,0-10.15-1.71-19-6-25.37-5.13-7.19-12.4-11.42-21.81-11.42-8.34,0-19.46,5.7-28.66,13.53-.22.21-1.63,1.55-1.59-.53s.35-6.31.54-9a4.6,4.6,0,0,0-1.4-3.92c-6,3-22.85,7-29.08,7.61-4.55.88-5.7,5.26-.85,6.77l.07,0a41.79,41.79,0,0,1,11.05,4.84c1.92,1.48,1.7,3.6,1.7,5.29.21,14.17.21,35.95-.42,47.8-.22,4.65-1.5,6.34-4.93,7.19l.32-.11a66.65,66.65,0,0,1-7.92,1.48c-1.07,1.05-1.07,7.19,0,8.46,2.14,0,13.05-.63,22-.63,12.4,0,18.82.63,22,.63,1.29-1.48,1.71-7.19.85-8.46a45.12,45.12,0,0,1-8.77-1.27c-3.42-.84-4.27-2.54-4.48-6.34-.43-9.94-.43-31.09-.43-45.47,0-4,1.06-5.92,2.35-7,4.28-3.81,11.33-6.34,17.54-6.34,6,0,10.05,1.9,13,4.44,4.07,3.38,5.56,8.24,6,11.84.86,8,.64,24.11.64,38.06,0,7.62-.64,9.53-3.42,10.37-1.28.63-4.71,1.27-8.77,1.69-1.28,1.27-.86,7.19,0,8.46,5.56,0,12-.63,21.39-.63,11.76,0,19.24.63,22.24.63,1.28-1.48,1.71-7,.86-8.46"
      />
      <path
        fill={fill}
        d="M131.69,120.49C116.44,53.19,80.4,31.06,76.53,22.61,72.27,16.63,68,6,68,6c-.08-.17-.19-.49-.32-.8-.44,5.95-.67,8.27-6.3,14.29C52.62,26.36,7.82,63.94,4.17,140.41c-3.4,71.29,51.52,113.79,59,119.21l.84.59,0,0c.05.35,2.36,17,4,34.57l5.81,0a344.12,344.12,0,0,1,6.14-37l.45-.3a97.92,97.92,0,0,0,9.48-7.76l.34-.31a136.77,136.77,0,0,0,43.89-102.11A163.1,163.1,0,0,0,131.69,120.49ZM67.32,219.36s0-100,3.35-100c2.57,0,5.91,129,5.91,129C72,247.82,67.32,227.14,67.32,219.36Z"
      />
    </svg>
  );
}
